// import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className='narrow-column'>
          <div className='narrow-section contact'>
            <h3>
              Contact
            </h3>
            <p className='link'>
              <a
                className='email-link'
                href='mailto:luciano.mogorovic@gmail.com'
                target='_blank'
                rel='noreferrer noopener'
              >
                ✉️&nbsp;luciano.mogorovic@gmail.com
              </a>
            </p>
            <p className='link'>
              <a
                className='site-link'
                href='https://lucianomogorovic.dev/'
                target='_blank'
                rel='noreferrer noopener'
              >
                💻&nbsp;lucianomogorovic.dev
              </a>
            </p>
            <p className='link'>
              <a
                className='phone-link'
                href='tel:2166591931'
                target='_blank'
                rel='noreferrer noopener'
              >
                📞&nbsp;(216) 659-1931
              </a>
            </p>
            <p className='link'>
              <a
                className='map-link'
                href='https://goo.gl/maps/QBJpWYupdM12hRhe9'
                target='_blank'
                rel='noreferrer noopener'
              >
                📍&nbsp;Cleveland, OH
              </a>
            </p>
          </div>
          <div className='narrow-section education'>
            <h3>
              Education
            </h3>
            <p className='school-name'>
              University of Akron
            </p>
            <p className='school-desc'>
              BS, Computer Science
            </p>
            <p className='school-desc'>
              2018
            </p>
          </div>
          <div className='narrow-section skills'>
            <h3>
              Skills
            </h3>
            <ul>
              <li>JavaScript</li>
              <li>TypeScript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>SCSS</li>
              <li>PHP</li>
              <li>Node.js</li>
              <li>React</li>
              <li>Redux</li>
              <li>Angular</li>
              <li>WordPress</li>
              <li>Craft CMS</li>
              <li>C#</li>
              <li>SQL</li>
              <li>.NET Core</li>
              <li>.NET Framework</li>
            </ul>
          </div>
        </div>
        <div className='main-column'>
          <div className='name-section'>
            <h1>
              Luciano<br />Mogorovic
            </h1>
            <span>
              Software Developer
            </span>
          </div>
          <div className='experience-section'>
            <h2 className='exp-header'>EXPERIENCE</h2>
            <div className='exp'>
              <h3>SENIOR WEB DEVELOPER</h3>
              <p className='company'><i>NESNADNY+SCHWARTZ</i><br />March 2021 - June 2023</p>
              <ul>
                <li>Worked with leadership to shape the high-level digital/web strategy of the overall company as well as the more specific digital/web strategies of each client, tailored to their specific needs.</li>
                <li>Managed multiple clients' website(s)/program(s) that required scheduled yearly updates. This included meeting with the client, discussing all planned updates and any new requests a client might have for a given year, communicating with the client to make sure any and all client-provided data/assets were received, as well as doing all the development to actually implement the update.</li>
                <li>Managed multiple clients' website(s)/program(s) on a maintenance basis including fixing all issues or bugs that would arise as well as addressing/implementing all unplanned new requests a client might bring up throughout a year.</li>
                <li>Managed code repositories for all projects through GitLab/GitHub as well as all builds and deployments for all projects.</li>
                <li>Managed all linux hosted servers on which client's website(s)/program(s) would live. This included logging into a server when necessary to run commands or manage files as well as handling all communication with the hosting provider.</li>
                <li>As the only developer at the company, was responsible for all other digital/technical needs of the company itself as well as all clients not specifically listed above.</li>
              </ul>
            </div>
            <div className='exp'>
              <h3>SOFTWARE DEVELOPER</h3>
              <p className='company'><i>UNION HOME MORTGAGE</i><br />May 2018 - March 2021</p>
              <ul>
                <li>Worked collaboratively with various clients.</li>
                <li>Helped determine project architecture prior to beginning the development process.</li>
                <li>Shifted priorities based on the needs of various clients and schedules.</li>
                <li>Lead developer on a project that allows employees to order appraisal inspections for the purchase of a new home using React/Redux on the frontend and .NET Core on the backend.</li>
                <li>Upgraded applications using .NET Framework/.NET Core 1.x/2.x to .NET Core 3.x.</li>
                <li>Collaborated with a specialized team to transition frontend technology from MVC to React/Redux.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
